import React, { useEffect } from "react";
import Search from "../../components/home/search";
import { isEmpty } from "lodash";
import Layout from "../../components/layout";
import Link from "gatsby-link";
import SEO from "../../components/seo";
import SliderIn from "../../components/slider2.js";
import SliderPhoto from "../../components/slider";
// import HowWorks from "../../components/how-works/index.js";

const FrontPage = (props) => {
  useEffect(() => {
    console.log("Welcome to shroom drinks!");
  }, []);

  const {
    pageContext: {
      //page: {  seo, uri },
      categories,
      categoryName,
      postSearchData: { products, options },
    },
  } = props;

  return (
    <Layout>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={"Healthy & natural energy drinks"}
            description={
              "The healthy, natural energy drinks with cordyceps militaris, lion's mane and l'theanine."
            }
            header={{ siteTitle: " Shroom Drinks | Shop" }}
          />

          {/* Hero */}
          <div
            id="hwrap"
            className="container-fluid mush"
            style={{
              padding: "0",
              minHeight: "620px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              id="hero"
              className="mask container-fluid"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                width: "100%",
                minWidth: "100%",
                height: "100%",
                minHeight: "800px",
                justifyContent: "center",
              }}
            >
              <div
                className="col-8"
                style={{
                  minHeight: "400px",
                  display: "block",
                  textAlign: "center",
                }}
              >
                <h1
                  className="text-white mob-hero spec-h"
                  style={{
                    textAlign: "center",
                    fontSize: "5em",
                    wordBreak: "break-word",
                  }}
                >
                  shroom drink
                </h1>
                <br />
                <h2
                  className="text-white"
                  style={{
                    textAlign: "center",
                    fontSize: "1.5em",
                    fontWeight: "800",
                    letterSpacing: "2px",
                  }}
                >
                  Your new wellness drink - made with natural ingredients and
                  backed by scientific research
                </h2>
                <p
                  className="text-white"
                  style={{
                    textAlign: "center",
                    margin: "auto",
                    maxWidth: "500px",
                    color: "#111",
                  }}
                >
                  <br />
                  <br />
                  <a
                    className="btn btn-outline-light spec-h btn-lg"
                    href="#mushroom-drinks"
                    role="button"
                    style={{ fontVariant: "small-caps" }}
                  >
                    shop now&nbsp;
                    <span role="img" aria-label="shopping">
                      🛒
                    </span>
                  </a>
                </p>
                <br />
                <div
                  style={{
                    padding: "2em",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  <a
                    id="go"
                    href="#mushroom-drinks"
                    style={{ fontSize: "15px" }}
                  >
                    ⇩
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            className="container-fluid"
            id="txt-intro"
            style={{
              textAlign: "center",
              padding: "4rem 10rem",
              fontSize: "2rem",
              letterSpacing: "2px",
              backgroundColor: "#E8D3D0",
            }}
          >
            <p className="my-2">
              <span className="spec-h">shroom</span> drink is designed
              <sup>*</sup> for your holistic wellbeing and modern lifestyle.
              Packed with adaptogens, probiotics, vitamins, and minerals, it
              provides what your body truly needs — balance and effective,
              functional nutrition.
            </p>
            <div
              className="my-5"
              style={{
                fontSize: "1rem",
                fontWeight: "300",
              }}
            >
              <span>
                <sup>*</sup>When we say 'designed', we mean it. We applied
                design methodologies to create a holistic, functional drinking
                experience that supports your nutrition, mood, and overall
                health.
              </span>
            </div>
          </div>

          {/*<Carousel categories={ categories }/>*/}

          <Search
            products={products}
            initialProducts={products}
            engine={options}
            category={categoryName}
            categories={categories}
          />
        </>
      ) : (
        <div>Something went wrong</div>
      )}
      {/*<Revs />*/}
      <SliderPhoto />
      {/*<HowWorks />*/}
      <div
        className="container-fuild"
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          margin: "5em 0em",
          padding: "2em 1em",
        }}
      >
        <h2 className="spec-h">Learn more about our ingredients!</h2>
        <p>
          Shroom drinks are real superfoods, or more likely superdrinks! Check
          out their amazing ingredients.
        </p>
        <br />
        <br />
        <SliderIn />
        <br />
        <br />
        <br />
        <p style={{ textAlign: "center" }}>
          <Link to="/our-story/" className="btn btn-outline-dark mush">
            get to know us better&nbsp;
            <span role="img" aria-label="shroom">
              ✨
            </span>
          </Link>
        </p>
      </div>
      {/*<BlogModule />
      <br />
      <p style={{ textAlign: "center" }}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://shroom4you.com/blog/"
          className="btn btn-outline-dark mush"
        >
          Visit blog
        </a>
      </p>
      <br />
      <br />*/}
    </Layout>
  );
};
export default FrontPage;
